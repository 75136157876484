import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { css } from '@emotion/react'
import Picker from "../components/picker"

const IndexPage = (props) => {

  return (
    <Layout>
      <Seo title="Pause Button" />
      <div css={hero}>
        <h2>Ready to press the pause button?</h2>
        <p css={intro}>Time for a quick break! Take a peaceful moment of awareness to reconnect with yourself before diving back (re-energized) to your (meaningful) day.</p>
      </div>

      <Picker />

      <div css={quickNav}>
        <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">🔥</span> Focus</Link>
          <p>No more frustrating days. Reach your daily objectives while making time to live fully!</p>
        </div>
        <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">🧘‍♀️</span> Meditation</Link>
          <p>Ease your mind and body, find peace and calm through a meditation sessions on insightful topics!</p>
        </div>
        <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">🌬</span> Breathing</Link>
          <p>Your breath can calm or energize your body and mind, while improving overall health, take a deep breath in!</p>
        </div>
        <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">📚</span> Thoughts</Link>
          <p>Change your mind over some classic or modern ideaas from philosophers, thinkers, scientists. Learn and grow!</p>
        </div>
        <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">💪</span> Fitness <span css={titleTag}>soon</span></Link>
          <p>Connect to your body in movement, keep in shape and gain more strength every day while reducing stress.</p>
        </div>
        <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">🤸‍♂️</span> Yoga <span css={titleTag}>soon</span></Link>
          <p>Take a break to find balance, tonicity and alignment between your mind and body with these selected asanas.</p>
        </div>
        <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">🎉</span> Socializing <span css={titleTag}>soon</span></Link>
          <p>Take a break and connect to real humans around you: plan or participate to social activities, in real or virtually.</p>
        </div>
        {true && <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">💤</span> Nap <span css={titleTag}>soon</span></Link>
          <p>Get a satifying moment to rest and reboot your whole body and mind with a quick power-nap break.</p>
        </div>}
        {true && <div css={activityBlock}>
          <Link to="/"><span role="img" aria-label="emoji">🕺</span> Dance <span css={titleTag}>soon</span></Link>
          <p>Ready for some easy pleasureable dance moves? Take this time to bring back emotion and rythm into your day!</p>
        </div>}
        {false && <div css={activityBlock}>
          <Link to="/">Sex <span css={titleTag}>soon</span></Link>
        </div>}
        {false && <div css={activityBlock}>
          <Link to="/">Relationships <span css={titleTag}>soon</span></Link>
        </div>}
        {false && <div css={activityBlock}>
          <Link to="/">Self-Hypnosis <span css={titleTag}>soon</span></Link>
        </div>}
      </div>
    </Layout>
  )
}

export default IndexPage

const hero = css`
  text-align: center;
  padding: 66px 0 32px;
  max-width: 700px;
  margin: 0 auto;

  h2 {
    font-size: 74px; 

    @media screen and (max-width: 960px) {
      font-size: 45px;
    }
  }
`

const intro = css`
  font-size: 20px;
  margin-bottom: 40px;
  opacity: .6;
`

const quickNav = css`
  margin: 30px 0 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 -1px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 0;
    margin: 0;
  }
`

const activityBlock = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;
  padding: 40px;
  background-color: white;

  a {
    display: block;
    color: #111;
    text-decoration: none;
    font-size: 22px;
    margin-bottom: 6px;
  }

  p {
    margin: 0;
  }
`

const titleTag = css`
  color: white;
  font-size: 10px;
  font-weight: 600;
  background-color: #EB5757;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px 8px;
`