import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import { css } from '@emotion/react'

import PushButton from './svg/pushButton'

const Picker = () => {
    const [pause, setPause] = useState(null)

    const data = useStaticQuery(graphql`
      query pickerQuery {
        pauses: allAirtable(filter: {id: {ne: "null"}, data: {Status: {eq: "Done"}, Slug: {ne: "null"}}}) {
          edges {
            node {
              id
              data {
                Name
                Slug
              }
              fields {
                category
              }
            }
          }
        }
      }
    `)

    const pauses = data.pauses.edges.map( edge => edge.node )
    // console.log(pauses)

    const pickOne = () => {
        const randomNumber = Math.floor(Math.random() * pauses.length) - 1
        // console.log("Tombola result:", randomNumber)
        setPause(pauses[randomNumber])
        // console.log("Pause", pause)
        if ( typeof window !== "undefined" ) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'button_pushed'
            });
        }
    }

    const pauseClicked = () => {
        if ( typeof window !== "undefined" ) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'pause_clicked'
            });
        }
    }

    return (
        <div css={main}>
            {!pause && <button onClick={pickOne} css={btn} >
                <PushButton />
            </button>}

            {pause && <div css={activity}>
                <h4><span role="img" aria-label="time">⏲️</span> Let's pause and practice <span className="pause-cat">{pause.fields.category}</span></h4>
                <h2>{pause.data.Name}</h2>
                <Link to={`/${pause.fields.category}/${pause.data.Slug}`} onClick={pauseClicked}>Begin the pause!</Link>
            </div>}
        </div>
    )
}

export default Picker

const main = css`
    margin: 0 auto 100px;
    max-width: 500px;
    text-align: center;
`

const btn = css`
    border: none;
    background: none;
    cursor: pointer;

    svg {
        width: 321px;
        height: auto;
        
        .color {
            transition: fill ease-in-out .32s;
        }

        &:hover .color {
            fill: #00FF88;
        }
    }
`

const activity = css`
    border: 1px solid #EFEFEF;
    border-radius: 32px;
    background-color: white;
    color: black;
    overflow: hidden;
    box-shadow: 0 0 0 30px rgba(0, 0, 0, .03);
    margin: 0 30px;

    h4 {
        background: rgba(0, 0, 0, .01);
        border-bottom: 1px solid rgba(0, 0, 0, .08);
        padding: 10px 0;
        color: #939290;
        font-size: .8rem;

        .pause-cat {
            background-color: #939290;
            padding: 4px 5px;
            border-radius: 5px;
            color: white;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 10px;
        }
    }

    h2 {
        padding: 0 20px;
    }

    a {
        display: block;
        width: 100%;
        padding: 20px;
        background-color: black;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        transition: color ease-in-out .11s, background-color ease-in-out .11s;

        &:hover {
            color: black;
            background-color: #00FF88;
        }
    }
`