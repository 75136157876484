import React from "react"

import { css } from '@emotion/react'

// https://react-svgr.com/playground/
// https://magic.reactjs.net/htmltojsx.htm
// http://htmlbeautify.com/

const PushButton = (props) => {

    return (
        <svg width="141" height="82" viewBox="0 0 141 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1" fill="white">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M141 39.2437H0V61.1639V61.7563H0.0282193C1.0944 72.9341 32.2376 81.8992 70.5 81.8992C108.762 81.8992 139.906 72.9341 140.972 61.7563H141V61.1639V39.2437Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M141 39.2437H0V61.1639V61.7563H0.0282193C1.0944 72.9341 32.2376 81.8992 70.5 81.8992C108.762 81.8992 139.906 72.9341 140.972 61.7563H141V61.1639V39.2437Z" fill="white"/>
            <path d="M0 39.2437V36.2437H-3V39.2437H0ZM141 39.2437H144V36.2437H141V39.2437ZM0 61.7563H-3V64.7563H0V61.7563ZM0.0282193 61.7563L3.01466 61.4714L2.75568 58.7563H0.0282193V61.7563ZM140.972 61.7563V58.7563H138.244L137.985 61.4714L140.972 61.7563ZM141 61.7563V64.7563H144V61.7563H141ZM0 42.2437H141V36.2437H0V42.2437ZM3 61.1639V39.2437H-3V61.1639H3ZM3 61.7563V61.1639H-3V61.7563H3ZM0.0282193 58.7563H0V64.7563H0.0282193V58.7563ZM70.5 78.8992C51.5707 78.8992 34.5094 76.6782 22.1417 73.1354C15.9418 71.3594 11.0726 69.2936 7.75722 67.1129C4.34227 64.8666 3.15027 62.8931 3.01466 61.4714L-2.95823 62.0411C-2.56074 66.2084 0.539971 69.5472 4.45994 72.1257C8.47948 74.7696 13.9874 77.0409 20.4894 78.9034C33.5254 82.6376 51.1669 84.8992 70.5 84.8992V78.8992ZM137.985 61.4714C137.85 62.8931 136.658 64.8666 133.243 67.1129C129.927 69.2936 125.058 71.3594 118.858 73.1354C106.491 76.6782 89.4293 78.8992 70.5 78.8992V84.8992C89.8331 84.8992 107.475 82.6376 120.511 78.9034C127.013 77.0409 132.521 74.7696 136.54 72.1257C140.46 69.5472 143.561 66.2084 143.958 62.0411L137.985 61.4714ZM141 58.7563H140.972V64.7563H141V58.7563ZM138 61.1639V61.7563H144V61.1639H138ZM138 39.2437V61.1639H144V39.2437H138Z" fill="black" mask="url(#path-1-inside-1)"/>
            <path d="M139.5 39.8361C139.5 41.9581 138.029 44.2935 134.603 46.6757C131.222 49.0272 126.23 51.2054 119.928 53.0591C107.342 56.7608 89.8663 59.0714 70.5 59.0714C51.1337 59.0714 33.6579 56.7608 21.0722 53.0591C14.7695 51.2054 9.77829 49.0272 6.3966 46.6757C2.97078 44.2935 1.5 41.9581 1.5 39.8361C1.5 37.7141 2.97078 35.3788 6.3966 32.9965C9.77829 30.645 14.7695 28.4668 21.0722 26.6131C33.6579 22.9114 51.1337 20.6008 70.5 20.6008C89.8663 20.6008 107.342 22.9114 119.928 26.6131C126.23 28.4668 131.222 30.645 134.603 32.9965C138.029 35.3788 139.5 37.7141 139.5 39.8361Z" fill="white" stroke="black" stroke-width="3"/>
            <line x1="133.5" y1="53.5" x2="133.5" y2="61.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="127.5" y1="56.5" x2="127.5" y2="61.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="6.5" y1="53.5" x2="6.5" y2="61.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="11.5" y1="60.5" x2="11.5" y2="65.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <mask id="path-8-inside-2" fill="white">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M130 17H11V35.5V36H11.0238C11.9237 45.4338 38.2076 53 70.5 53C102.792 53 129.076 45.4338 129.976 36H130V35.5V17Z"/>
            </mask>
            <g css={upDown}>
                <path className="color" fill-rule="evenodd" clip-rule="evenodd" d="M130 17H11V35.5V36H11.0238C11.9237 45.4338 38.2076 53 70.5 53C102.792 53 129.076 45.4338 129.976 36H130V35.5V17Z" fill="white"/>
                <path d="M11 17V14H8V17H11ZM130 17H133V14H130V17ZM11 36H8V39H11V36ZM11.0238 36L14.0103 35.7151L13.7513 33H11.0238V36ZM129.976 36V33H127.249L126.99 35.7151L129.976 36ZM130 36V39H133V36H130ZM11 20H130V14H11V20ZM14 35.5V17H8V35.5H14ZM14 36V35.5H8V36H14ZM11.0238 33H11V39H11.0238V33ZM70.5 50C54.5557 50 40.2017 48.1287 29.8159 45.1537C24.6069 43.6615 20.5473 41.9341 17.8041 40.1297C14.9614 38.2599 14.1043 36.7008 14.0103 35.7151L8.03737 36.2849C8.39329 40.0161 11.1591 42.9405 14.5069 45.1425C17.9542 47.4101 22.6525 49.343 28.1636 50.9217C39.2178 54.0882 54.1519 56 70.5 56V50ZM126.99 35.7151C126.896 36.7008 126.039 38.2599 123.196 40.1297C120.453 41.9341 116.393 43.6615 111.184 45.1537C100.798 48.1287 86.4443 50 70.5 50V56C86.8481 56 101.782 54.0882 112.836 50.9217C118.347 49.343 123.046 47.4101 126.493 45.1425C129.841 42.9405 132.607 40.0161 132.963 36.2849L126.99 35.7151ZM130 33H129.976V39H130V33ZM127 35.5V36H133V35.5H127ZM127 17V35.5H133V17H127Z" fill="black" mask="url(#path-8-inside-2)"/>
                <path className="color" d="M128.5 17.5C128.5 19.1753 127.337 21.0851 124.468 23.0803C121.643 25.0447 117.457 26.8743 112.15 28.4353C101.555 31.5515 86.8287 33.5 70.5 33.5C54.1713 33.5 39.4455 31.5515 28.8504 28.4353C23.543 26.8743 19.3572 25.0447 16.5322 23.0803C13.663 21.0851 12.5 19.1753 12.5 17.5C12.5 15.8247 13.663 13.9149 16.5322 11.9197C19.3572 9.95526 23.543 8.12567 28.8504 6.56468C39.4455 3.44847 54.1713 1.5 70.5 1.5C86.8287 1.5 101.555 3.44847 112.15 6.56468C117.457 8.12567 121.643 9.95526 124.468 11.9197C127.337 13.9149 128.5 15.8247 128.5 17.5Z" fill="white" stroke="black" stroke-width="3"/>
                <line x1="26.1184" y1="14.1115" x2="33.1115" y2="21.8816" stroke="black" stroke-width="3" stroke-linecap="round"/>
                <line x1="31.1159" y1="12.1511" x2="42.1511" y2="24.8841" stroke="black" stroke-width="3" stroke-linecap="round"/>
            </g>
            <path d="M8 36.5L12 34L13 37.5L21.5 44L37.5 48.5L55 50.5L73.5 52L90.5 50.5L104 48.5L116 45L125.5 40.5L128.5 35L133.5 36.5L136 42L122 50L99 55L67.5 57L42 55L20.5 50.5L8 45V36.5Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5127 33C13.3482 33 14.0254 33.6396 14.0254 34.4286C14.0254 36.1306 15.1949 38.0137 17.9696 39.9531C20.715 41.872 24.7843 43.6603 29.9487 45.1871C40.2596 48.2353 54.5969 50.1429 70.5 50.1429C86.4031 50.1429 100.74 48.2353 111.051 45.1871C116.216 43.6603 120.285 41.872 123.03 39.9531C125.805 38.0137 126.975 36.1306 126.975 34.4286C126.975 33.6396 127.652 33 128.487 33C129.323 33 130 33.6396 130 34.4286C130 37.4604 127.924 40.0871 124.83 42.2496C121.707 44.4326 117.284 46.3383 111.955 47.9137C101.279 51.07 86.6224 53 70.5 53C54.3776 53 39.7213 51.07 29.0449 47.9137C23.7157 46.3383 19.2929 44.4326 16.1697 42.2496C13.0757 40.0871 11 37.4604 11 34.4286C11 33.6396 11.6773 33 12.5127 33Z" fill="black"/>
        </svg>    
    )
}

export default PushButton

const upDown = css`
    @keyframes up-down {
        from {transform: translateY(0);}
        to {transform: translateY(3px);}
    }

    animation: up-down 2s ease-in-out 0s infinite alternate;
`